.navbar{
  padding-top: 15px;
  padding-bottom: 47px;
  position: relative;
  background-image: url('../images/nav-bg.png');
  background-position: bottom center;
  border: none;
  @media (min-width: 1200px){
    padding-bottom: 80px;
  }
}

.nav-collapse-component{
  @media (min-width: 768px){
    display: block;
  }
}
.menu-side{
  padding: 0 15px;
}
.nav-top-row{
  display: flex;
  @media (max-width: 1199px) {
    flex-direction: column;
    padding-top: 10px;
  }
  .nav-brand{
    margin-bottom: 15px;
  }
  @media (min-width: 768px) {
    align-items: center;
    .nav-brand{
      margin-bottom: 0;
    }
  }
  .nav>li{
    display: inline-block;
    @media (max-width: 1199px) {
      display: block;
    }
  }
}
.nav-bottom-row{
  display: none;
  margin-top: 40px;

  @media (min-width: 768px){
    display: flex;
  }
}
.menu-mobile{
  @media (min-width: 1200px){
    display: none;
  }
}
.navbar-toggle {
  margin: 0;
  cursor: pointer;
  @media (min-width: 1200px){
    display: none;
  }
  @media (min-width: 768px){
    display: block;

  }
}
.navmenu{
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  li{
    padding: 10px 0;
    display: block;
    @media (min-width: 768px){
      display: inline-block;
      padding: 2px 8px;
    }
    a{
      color: var(--color-text);
      font-size: 16px;
      font-weight: normal;
      @media (min-width: 768px){
        font-size: var(--size-text-secondary);
        font-weight: bold;
      }
      &:hover{
        color: var(--color-title);
        text-decoration: none;
      }
    }
  }
}
.navmenu-pages{
  margin-left: 0;
  margin-bottom: 15px;
  @media (min-width: 1200px){
    margin-left: 37px;
    margin-bottom: 0;
  }
  li{
    @media (min-width: 768px){
      padding: 2px 6px;
    }
  }
}
.navmemu-contacts{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: 415px;    
    flex-direction: row;
  }
}
.contact-top-line{
  font-size: var(--size-text-subtitle);
}
.contact-bottom-line{
  font-size: var(--size-text-secondary);
  color: var(--color-text);
  letter-spacing: 0.7px;
}
.navmenu .contact-item{
  padding-left: 30px;
  padding-right: 0;
  position: relative;
  padding-top: 0;
  img{
    left: 0px;
    top: 6px;
    position: absolute;
  }
}
.order-call{
  color: var(--color-title);
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 1px dashed var(--color-title);
  cursor: pointer;
}
.nav-search-form{

  position: relative;
  input{
    border: none;
    background-color: #fff;
    padding: 15px 33px;
    border-radius: 50px;
    display: block;
    width: 100%;
    &::placeholder{
      font-style: italic;
      font-size: 13px;
    }
  }
  button{
    position: absolute;
    background-color: transparent;
    border: none;
    right: 26px;
    top: 13px;
  }
}
.nav-shop-menu{
  width: 189px;
  display: flex;
  justify-content: space-between;
  margin-right: -9px;
  margin-left: 60px;
  img.menu-icon{
    width: 23px;
    height: 21px;
  }
}
.card-dropdown{
  .dropdown-card{
    display: none;
    background-color: #fff;
    position: absolute;
    margin-top: 10px;
    z-index: 888;
    max-width: 320px;
    padding: 10px;
    box-shadow: 0 3px 10px 3px rgba(#b0b0b0, 0.3);
    border-radius: 5px;
    width: 320px;
    @media (max-width: 1430px) {
      right: 0;
    }
  }
  &.open {
    .dropdown-card {
      display: block;
    }
  }
}
.nav-component{
  .catalog-dropdown.open{
    .button-theme-large{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .dropdown-submenu{
    &.open {
      &>.dropleft-btn{
        color: var(--color-title);
      }
    }
    .dropleft-btn{
      display: flex;

    }
  }

}
.dropleft-btn{
  .left-caret{
    display: inline-block;
    margin-left: auto;
  }
}
.menu-mobile{
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  align-items: center;
  @media (min-width: 767px){
    display: none;
  }
  .mobile-cart-link{
    padding: 10px;
  }
  .nav-search-form{
    margin-left: 0;
    flex-grow: 1;
    input{
      padding: 10px 20px;
    }
    button{
      right: 7px;
      top: 7px;
    }
  }
}
.menu-main{
  &.collapse{
    @media (max-width: 767px) {
      position: fixed;
      z-index: 999;
      background-color: #eaf5fc;
      top: 0;
      left: 0;
      max-width: 280px;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 20px 5px #b0b0b0;
      padding: 20px;
      overflow: auto;
    }
  }
}

.mobile-collapse-layout{
  display: none;
  position: fixed;
  z-index: 998;
  background-color: #121212;
  opacity: 0.65;
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.navmenu-mobile-funcs{
  display: block;
  margin-bottom: 25px;
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #b0b0b0;
  }
  img{
    width: 23px;
    height: 21px;
    margin-right: 10px;    
  }
  a{
    display: block;
  }
  @media (min-width: 768px) {
    display: none;
  }
  .amount{
    color: var(--color-title);
    font-weight: bold;
    display: inline-block;
    margin-left: auto;
    float: right;
  }
}

.mobile-catalog{
  .modal-body{
    &>.dropdown-list{
      display: block;
      li{
        border-bottom: 1px solid #b0b0b0;
      }
    }
  }
  .dropdown-list{
    position: static;
    padding: 0;
    margin: 0;
    background-color: transparent!important;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    //a, .dropleft-btn{
    //  border-bottom: 1px solid #b0b0b0;
    //  padding-left: 0;
    //  padding-right: 0;
    //}
    .dropdown-list{
      padding-left: 20px;
      .dropdown-list{
        padding-left: 40px;
      }
    }
  }
  .dropleft-btn{
    display: flex!important;
  }
}
.search-bar{
  margin-left: 30px;
  flex-grow: 1;
}
.dropdown-brand{
  position: absolute;
  z-index: 111;
  display: none;
  background-color: #fff;
  padding: 7px;
  border-radius: 6px;
  box-shadow: 0 3px 11px 3px rgba(#b0b0b0, 0.3);
  width: 200px;
  a{
    display: block;
    padding: 4px 0;
  }
}
.brands-group-item{
  &:hover{
    .dropdown-brand{
      display: block;
    }
  }
}
.brands-group{
  &-mobile{
    display: flex;
    margin-bottom: 30px;
    @media (min-width: 1200px) {
      display: none;
    }
  }
  &-desctop{
    display: none;
    padding: 10px 30px 0 30px;
    @media (min-width: 1200px) {
      display: flex;
    }
  }

  display: flex;
  font-size: 13px;
  .brands-group-title{
    flex: 0 0 139px;
    padding-right: 20px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .brands-group-dropdowns{
    flex-basis: 100%;
  }
  .brands-group-item{
    display: inline-block;
    div[type="button"]{
      font-weight: bold;
      display: inline-block;
      padding: 5px 8px;
      cursor: pointer;
      &:hover{
        color: var(--color-title);
      }
    }
  }
}