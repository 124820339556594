:root{
  --color-title: #0f4c81;
  --color-text: #000000;
  --color-text-secondary: #696968;
  --color-text-footer: #ffffff;
  --size-text-secondary: 13px;
  --size-text-primary: 15px;
  --size-text-subtitle: 18px;
  --size-text-section: 28px;
  --size-text-title: 60px;
}
