.disabled-link{
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}
.caret-left{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-left: 4px dashed;
  border-top: 4px solid\9;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}
$dplistbgc: #fafafa;
.dropdown-list {
  display: none;
  position: absolute;
  background-color: $dplistbgc;
  box-shadow: 0 0 20px 1.5px #ccc;
  padding: 15px 0;
  border-radius: 4px;
  z-index: 999;
  width: 100vw;
  max-width: 255px;
  list-style-type: none;
  li{
    a, .dropleft-btn{
      display: block;
      padding: 15px 25px;
      font-size: 18px;
      color: #000;
      font-weight: bold;
      &:hover{
        color: var(--color-title);
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .dropdown-list {
    background-color: darken($color: $dplistbgc, $amount: 2.5);
    a, .dropleft-btn{
      font-size: 16px;
    }
    .dropdown-list{
      background-color: darken($color: $dplistbgc, $amount: 5);
    }
  }
}
.dropdown.open{
  &>.dropdown-list{
    display: block;
  }
}
.dropdown-submenu{
  .dropdown-list{
    top: 0;
    left: 100%;
    min-height: 100%;
  }
}

@media (min-width: 1200px){
  .container{
    width: 1140px;
  }
}
.mb25{
  margin-bottom: 25px;
}
.mb15{
  margin-bottom: 15px;
}
.mb50{
  margin-bottom: 50px;
}

.margin-left-sm-auto{
  @media (min-width: 768px){
    margin-left: auto;
  }
}
.row-md-flex{
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  @media (min-width: 768px){
    display: flex;
    &>[class^='col-'] {
      float: none;
    }
  }
}
.row-flex{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  &>[class^='col-'] {
    float: none;
  }
}
.w-100{
  width: 100%;
}