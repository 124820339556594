
h1{
  font-size: 24px;
  font-weight: bold;
}
*:focus{
  outline: none;
}
h2{
  font-size: 20px;
  font-weight: bold;
}
.custom-h1{
  font-family: 'Pattaya', sans-serif;
  color: var(--color-title);
  font-size: var(--size-text-title);
  margin-bottom: 48px;
  font-weight: normal;
  @media (max-width: 767px)  {
    font-size: 30px;
  }
}
.custom-h2{
  font-family: 'Pattaya', sans-serif;
  color: var(--color-title);
  font-size: var(--size-text-section);
  margin-bottom: 48px;
  font-weight: normal;
  @media (max-width: 767px)  {
    font-size: 18px;
  }
}


p{
  margin: 0;
}
h3{
  font-size: 16px;
  font-weight: bold;
}
h4{
  font-size: 14px;
  font-weight: bold;
}
.icon-bar{
  background-color: #000000;
}
body{
  font-family: Roboto;
}
.text-block-container{
  background-image: url('../images/text-block-bg.png');
  background-position: top;
  background-size: cover;
  padding-top: 130px;
}
.button-theme{
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
  &-blue{
    background-color: var(--color-title);
    color: var(--color-text-footer)!important;
    border-color:var(--color-title);
    &:hover{
      background-color:lighten(#0f4c81, 5%);
    }

  }
  &-white{
    color: #fff;
    border-color: #fff;
  }
  &-large{
    padding: 13px 37px;
    border-radius: 25px;
    font-size: 17px;
    letter-spacing: -0.9px;
    .caret{
      margin-left: 24px;
      border-top: 5px dashed;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
  &-outline{
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    &:hover{
      background-color: darken(#fff, 5%);
    }
  }
  &-normal{
    padding: 9px 30px;
    border-radius: 40px;
    font-size: 14px;
  }
  &:hover{
    text-decoration: none;
  }
}
.goods-list{
  padding-top: 5px;
}
.goods-item{
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  &_layout{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding-bottom: 0;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0px rgba(#9f9f9f, 0.25);
    transition: 0.3s;
    button{
      transition: 0.3s;
      margin-top: auto;
      margin-left: auto;
      margin-right: auto;
      display: none;
    }
  }
  &_container{
    position: relative;
  }
  &_label{
    position: absolute;
    top: -4px;
    left: 4px;
    border-radius: 5px;
    color: #fff;
    padding: 3px 13px;
    font-size: 13px;
    z-index: 3;
    &-green{
      background-color: #77aa52;
    }
    &-orange{
      background-color: #dc8e3a;
    }
  }
  &_controllers{
    position: absolute;
    z-index: 3;
    top: 0px;
    right: 0px;
  }
  &_controller{
    padding: 7px;
    transform: scale(0.8);
    opacity: 0.5;
    cursor: pointer;
    display: none;
    transition: 0.3s;
    img{
      width: 21px;
      height: 21px;
    }
    &.highlighted{
      display: block;
      opacity: 1;
    }
  }
  &_image{
    padding-top: 76%;
    position: relative;
    display: block;
    img{
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &_description{
    font-size: 14px;
    padding: 0 42px;
    height: 40px;
    overflow: hidden;
    display: block;
    color: var(--color-text);
    &:hover{
      text-decoration: none;
      color: var(--color-text);

    }
  }
  &_name{
    display: block;
    font-size: var(--size-text-subtitle);
    padding-top: 8px;
    padding-bottom: 3px;
    font-weight: bold;
    height: 33px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text);
    &:hover{
      text-decoration: none;
      color: var(--color-text);

    }
  }
  &:hover{
    z-index: 33;
    .goods-item_controller{
      display: block;
    }
    .goods-item_layout{
      height: calc(100% + 75px);
      padding-bottom: 25px;
      button{
        display: inline-block;
      }
    }
  }
  &_price{
    margin-top: 6px;
    .current{
      font-size: 18px;
      color: var(--color-title);
      font-weight: bold;
    }
    .previous{
      text-decoration: line-through;
    }
  }
  &_state{
    color: #77aa52;
    font-size: 13px;
    font-weight: bold;
    &-order{
      color: #dc8e3a;
    }
  }
}
.goods-header{
  margin-bottom: 30px;
  button{
    @media (min-width: 768px) {
      display: none;
    }
  }
}
.goods-filter{
  @media (min-width: 768px) {
    .modal{
      position: static;
      &.fade{
        opacity: 1;
        display: block;
      }
    }
    .modal-dialog{
      margin: 0;
      width: auto;
      position: static;
      transform: none!important;
    }
    .modal-content{
      border: none;
    }
    .modal-content{
      box-shadow: none;
      background-color: transparent;
      position: static;
    }
    .modal-header{
      display: none;
    }
    .modal-body{
      position: static;
      padding: 0;
    }
  }
  .scroll-wrapper{
    height: 100%;
  }
  &_group{
    border-bottom: 1px solid #b0b0b0;
    padding-bottom: 19px;
    &:not(:first-child){
      padding-top: 36px;
    }
  }
  &_group-name{
    color: var(--color-title);
    font-weight: bold;
  }
  &_group-controll{
    overflow: hidden;
  }
  &_group-header{
    display: flex;
    justify-content: space-between;
    font-size: var(--size-text-subtitle);
    padding-bottom: 15px;
    padding-right: 12px;
    i{
      font-weight: bold;
      transform: rotate(90deg);
      width: 12px;
      color: #b0b0b0;
      &:hover{
        color: var(--color-title);
        cursor: pointer;
      }
    }
  }
  &_group-row{
    display: block;
    font-weight: normal;
    cursor: pointer;
    padding-left: 38px;
    padding-bottom: 4px;
    padding-top: 3px;
    position: relative;
    user-select: none;
    margin: 0;
    letter-spacing: 1px;
    &.initial-hidden{
      display: none;
    }
  }
  &_item-amount{
    font-size: var(--size-text-subtitle);
    &::before{
      content: "(";
    }
    &::after{
      content: ")";
    }
  }
  &_group-adder{
    font-weight: bold;
    padding-left: 38px;
    padding-top: 5px;
    color: #b0b0b0;
    letter-spacing: 0.3px;
    user-select: none;
    &:hover {
      cursor: pointer;
      color: var(--color-title);
    }
  }
  &_item-checkbox{
    display: none;
    & + span{
      &::before{
        content: ' ';
        position: absolute;
        display: inline-block;
        left: 0;
        top: 8px;
        height: 14px;
        width: 14px;
        border-radius: 2px;
      }
      &::after{
        content: ' ';
        position: absolute;
        display: inline-block;
        left: 2px;
        top: 11px;
        height: 6px;
        width: 10px;
        border-radius: 2px;
        transform: rotate(-45deg);
      }
    }

    &:not(:checked){
      & ~ span{
        color: #b0b0b0;
      }
      & + span{
        &::before{
          border: 1px solid #b0b0b0;
        }
      }
    }
    &:checked{
      & ~ span{
        color: var(--color-title);
      }
      & + span{
        &::before{
          border: 1px solid var(--color-title);
        }
        &::after{
          border-bottom: 2px solid var(--color-title);
          border-left: 2px solid var(--color-title);
        }
      }
    }
  }
}

.pagination-list{
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
  padding-top: 32px;
}
.pagination-item{
  margin: 0 3px;
  @media (min-width: 768px){
    margin: 0 8px;
  }
  a{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #361d32;
    background-color: #f0f0f0;
    font-size: 12px;
    width: 25px;
    height: 25px;
    @media (min-width: 768px){
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
    &.disabled{
      pointer-events: none;
      user-select: none;
      opacity: 0.3;
    }
    &.current{
      pointer-events: none;
      user-select: none;
      background-color: var(--color-title);
      color: #fff;
    }
    &:hover{
      cursor: pointer;
      background-color: var(--color-title);
      color: #fff;
      text-decoration: none;
    }
    &.prev-page,
    &.next-page{
      i{
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}
.custom-footer{
  padding-top: 80px;
  color: #fff;
  a{
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
  h4{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 60px;
  }
  &::before{
    content: '';
    display: block;
    height: 100px;
    background-image: url('../images/footerbg.png');
    background-size: cover;
  }
  .footer-top-line{
    background-color: #0f4c81;
    padding-bottom: 20px;
    font-size: 16px;
    a{
      display: block;
      margin-bottom: 20px;
    }
  }
  .footer-contacts-group{
    font-size: 24px;
    padding-left: 60px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 0;
      top: 4px;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &:not(:last-child){
      margin-bottom: 30px;
    }
    &-tels{
      &::before{

        background-image: url('../pics/phone-call.svg');
      }
    }
    &-mail{
      &::before{
        background-image: url('../pics/envelope.svg');
      }
    }
    a{
      margin-bottom: 10px;
    }
  }
  .footer-bottom-line{
    background-color: #0d3f6b;
    font-size: 13px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.item-page_image-container{
  position: relative;
  padding-bottom: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0px rgba(#9f9f9f, 0.25);
  overflow: hidden;
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }
  .goods-item_label{
    top: 6px;
    left: 16px;
  }
}
.item-features{
  .option-name,
  .option-value{
    margin-bottom: 10px;
    font-size: 16px;
  }
  .option-name{
    color: #b0b0b0;
  }
  .option-value{
    color: var(--color-title);
    text-align: right;
  }
}
.card-block{
  margin-top: 15px;
  background-color: #faf5f2;
  border-radius: 10px;
  padding: 15px 20px 60px;
  @media (min-width: 768px) {
    padding: 15px 65px 60px;
  }
  &_top-line,
  &_bottom-line{
    display: flex;
    align-items: center;
  }
  &_bottom-line{
    margin-top: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .goods-item_controllers{
    position: static;
    display: flex;
    margin-left: auto;
    margin-right: -7px;
  }
  .goods-item_controller{
    display: block;
    opacity: 0.4;
    &:hover{
      opacity: 1;
    }
  }
  &_price{
    position: relative;
    .amount{
      font-size: 36px;
      font-weight: bold;
      color: var(--color-title);
    }
    .currency{
      color: var(--color-title);
      font-size: 18px;
    }
    .price-mark{
      position: absolute;
      bottom: 80%;
      left: 0;
      color: #b0b0b0;
      font-size: 16px;
    }
  }
  &_counter{
    padding: 0 15px;
    display: flex;
    align-items: center;
  }
  &_funcs{
    position: relative;
    flex-basis: 100%;
    text-align: center;
    @media (min-width: 992px) {
      flex-basis: auto;
    }
    .buy-in-one-click{
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      letter-spacing: 0.3px;
      margin-top: 10px;
      &:hover{
        color: var(--color-title);
        text-decoration: none;
      }
      span{
        padding-bottom: 2px;
        border-bottom: 2px dotted var(--color-title);
      }
    }
    .button-theme-normal{
      padding: 13px 50px;
      border-radius: 40px;
      font-size: 17px;
    }
  }
  &_counter{
    display: inline-flex;
    .amount{
      color: #553c51;
      background-color: #fff;
      font-size: 16px;
      padding: 10px 15px;
      border-radius: 40px;
    }
    &-dec,
    &-inc{
      border: none;
      background-color: transparent;
      padding: 0;
      font-weight: bold;
      font-size: 18px;
      color: #b0b0b0;
      display: inline-flex;
      width: 17px;
      height: 17px;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      &:hover{
        transform: scale(1.1);
      }
      &:active{
        transform: scale(0.9);
      }
    }
  }
}
.news-catalog-item-thumb{
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-title);
    opacity: 0.5;
    display: none;
  }
  .button-theme{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: transparent;
    opacity: 0;
    transition: 0.3s;
  }
}
.news-catalog-item{
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  display: block;
    &:hover{
    text-decoration: none;
    .news-catalog-item-name{
      color: var(--color-title);
    }
    .news-catalog-item-thumb{
      &::before{
        display: block;
      }

      .button-theme{
        opacity: 1;
        top: 50%;
      }
    }
  }

}
.news-catalog-item-date{
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  background-color: #fff;
  color: #b0b0b0;
  border-radius: 5px;
  &::before{
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    background-image: url(../pics/clock-solid.svg);
  }
}
.news-catalog-item-name{
  margin-top:  25px;
  margin-bottom: 20px;
  font-size: var(--size-text-subtitle);
  font-weight: bold;
  color: #000;
  overflow: hidden;
  height: 44px;
}
.news-item-page{
  .news-catalog-item-name{
    font-size: 16px;
  }
}
.news-catalog-item-desc{
  color: #b0b0b0;
  font-size: 14px;
  overflow: hidden;
  height: 63px;
}
.news-item-thumb{
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 25px;
  img{
    max-width: 100%;
  }
}
.content{
  margin-bottom: 45px;
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
      &::before{
        content: "\f00c";
        color: var(--color-title);
        font-family: FontAwesome;
      }
    }
  }
}
.delivery-rule{
  height: 100%;
  background-color: #fff;
  box-shadow: 3px 10px 10px 7px rgba(#b0b0b0, 0.3);
  padding: 20px;
  border-radius: 10px;
  img{
    margin-bottom: 25px;
  }
}
.delivery-name{
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 15px;
}
.delivery-desc{
  font-size: 14px;
  color: #b0b0b0;
}
.delivery-container{
  .row-flex{
    &>[class^='col-'] {
      margin-bottom: 30px;
    }
  }
  h2{
    font-size: 32px;
    font-weight: normal;
  }
  h3{
    font-size: 24px;
    font-weight: normal;
  }
  font-size: 16px;
}
.contacts-container{
  iframe{
    width: 100%;
  }
}
.contacts-item{
  margin-bottom: 30px;
  padding-left: 65px;
  position: relative;
  &::before{
    font-size: 24px;
    color: var(--color-title);
    font-family: "FontAwesome";
    position: absolute;
    left: 0;

  }

  .contacts-type{
    color: #b0b0b0;
  }
  .contacts-detail{
    font-size: 16px;
    font-weight: bold;
  }
  &-address{
    &::before{
      content: "\f041";
    }
  }
  &-tels{
    &::before {
      content: "\f095";
    }
    .contacts-detail{
      font-size: 24px;
    }
  }
  &-mail{
    &::before {
      content: "\f0e0";
    }
  }
  &-time{
    &::before {
      content: "\f017";
    }
  }
}
.label-title{
  display: block;
  margin-bottom: 15px;
}
.custom-input,
.custom-textarea{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #b0b0b0;
  padding: 15px;
}

.custom-textarea {
  height: 80px;
  resize: none;
}
.feedback-container{
  .custom-form{
    label{
      display: block;
      margin-bottom: 25px;
    }
    .button-theme{
      margin-bottom: 15px;
      letter-spacing: normal;
    }
    a{
      font-weight: bold;
    }
  }
}
.cart-item{
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 3px 11px 5px rgba(#b0b0b0, 0.3);
  min-height: 95px;
  padding: 10px;
  &-image{
    width: 15%;
    height: 100%;
    img{
      max-height: 100%;
      max-width: 100%;
    }
  }
  &-primary{
    padding: 0 7px;
    height: 100%;
    width: 63%;
  }
  &-name{
    font-size: 14px;
    letter-spacing: 0.3px;
  }
  &-func{
    width: 22%;
    height: 100%;
    .price{
      color: var(--color-title);
      .amount{
        font-size: 16px;
        font-weight: bold;
        
      }
    }
    .delete{
      margin-top: 16px;
      color: #b0b0b0;
    }
  }
  &-counter{
    .card-block_counter{
      margin-top: 7px;
      padding: 0;
      .amount{
        padding: 7px 15px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
.cart-container{
  .custom-form{
    label{
      display: block;
      margin-bottom: 25px;
    }
  }
}
.checkbox-block{
  display: inline-block;
  margin-right: 20px;
  border-radius: 2px;

  svg{
  }
}
.checked-radio{
  display: none;

  &:not(:checked){
    & ~ .checkbox-block{
      border: 1px solid #b0b0b0;
      width: 14px;
      height: 14px;
      svg{
        display: none;
      }
    }
    & ~ .form-radio-name{
      color: #b0b0b0;
    }
  }

  &:checked{
    & ~ .checkbox-block{
      border: none;
      width: 14px;
      height: 14px;

      svg{
        display: block;
      }

    }
    & ~ .form-radio-name{
      color: #000000;
      font-size: 14px;
    }

  }
}
.form-row-name{
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: normal;
  &-title{
    color: var(--color-title);
  }
}
.cart-summ{
  text-align: right;
  color: #b0b0b0;
  .price{
    color: var(--color-title);
    font-size: 24px;
    font-weight: bold;
  }
  .currency{
    color: var(--color-title);
  }
}
.brand-filter-item{
  display: block;
  height: 110px;
  padding: 25px;
  position: relative;

  background-color: #fff;
  border-right: 4px;
  box-shadow: 0 12px 8px 2px rgba(#b0b0b0, 0.3);
  margin-bottom: 25px;
  &:hover{
    transform: scale(1.04);
  }
  img{
    position: absolute;
    max-height: calc(100% - 50px);
    max-width: calc(100% - 50px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
}
.brand-filter-category{
  font-size: 24px;
  color: var(--color-title);
  margin-bottom: 10px;
}
.brand-filter-group{
  margin-bottom: 30px;
}
.masonry-grid{
  margin: auto;
  margin: -15px;
}
.grid-size {
  width: calc(100% / 3);
  @media (max-width: 767px){
    width: calc(100%);
  }
}
.grid-element{
  float: left;
  margin: 15px;
  position: relative;
  display: block;

  &--widthFull{
    width: calc(100% - 30px);
  }
  &--width33 {
    width: calc(100%/3 - 30px);
    @media (max-width: 768px) {
      width: calc(100% / 2 - 30px);
    }
    @media (max-width: 567px) {
      width: calc(100%  - 30px);
    }
  }
}
.article-preview{
  border-radius: 10px;
  display: block;
  &:hover{
    &.article-preview{

      &-type-1{
        box-shadow: 0 12px 8px 2px rgba(#0f4c81, 0.3);
      }
      &-type-2, &-type-3{
        box-shadow: 0 12px 8px 2px rgba(#0f4c81, 0.3);
      }
    }
    text-decoration: none;
    .article-preview-name{
      text-decoration: underline;
    }
  }
  &-button{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 130px;
    left: 35px;
    z-index: 3;
    background-color: transparent;
    border: 1px solid var(--color-title);
    border-radius: 50%;
    color: var(--color-title);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    @media (max-width: 767px) {
      display: none;
    }
    i{
      line-height: 0;
      vertical-align: middle;
      display: inline-block;
      margin-bottom: 2px;
      margin-left: 2px;
    }
  }
  &-type-1{
    background-color: #fff;
    position: relative;
    box-shadow: 0 12px 8px 2px rgba(#b0b0b0, 0.3);
    padding: 65px 30px 90px;

    .article-preview{
      &-button{
        width: 45px;
        height: 45px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        background-color: var(--color-title);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 24px;

        i{
          line-height: 0;
          vertical-align: middle;
          display: inline-block;
          margin-bottom: 4px;
          margin-left: 3px;
        }
      }
      &-name{
        color: var(--color-title);
        font-size: 60px;
        font-weight: bold;
        max-width: 300px;
        position: relative;
        @media (max-width: 767px) {
          font-size: 36px;
        }
        &::after{
          content: '';
          position: absolute;
          top: 50%;
          left: calc(100% + 50px);
          display: block;
          background-color: var(--color-title);
          height: 1px;
          width: 100%;
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      &-image{
        position: absolute;
        top: -20px;
        bottom: 0;
        right: 0;
        width: 45%;
        img{
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      &-desc{
        margin-top: 40px;
        font-size: 16px;
        font-weight: bold;
        max-width: 300px;
        color: #000;
        position: relative;
        z-index: 3;
      }
    }
  }
  &-type-2,
  &-type-3{
    background-color: #f4f4f4;
    position: relative;
    padding: 35px;

    .article-preview{
      &-name{
        color: #000;
        font-size: 28px;
        font-weight: bold;
        position: relative;
        height: 84px;
        overflow: hidden;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
      &-image{
        position: relative;
        padding-bottom: 65%;
        img{
          max-width: calc(100% + 60px);
          max-height: 100%;
          position: absolute;

          left: 50%;
          transform: translateX(-50%);
          bottom: -35px;
        }
      }
      &-desc{
        margin-top: 40px;
        font-size: 16px;
        font-weight: bold;
        max-width: 300px;
        color: #000;
      }
    }
  }
  &-type-2{
    .article-preview{
      &-image{
        padding-bottom: 65%;
      }
    }
  }
  &-type-3{
    .article-preview{
      &-image{
        padding-bottom: 110%;
      }
    }
  }

}
.home-page{
  .content{
    font-size: 16px;
    h2{
      font-size: 24px;
    }
  }
}
.author-details{
  margin-top: 10px;
  .author-name{
    font-size: 24px;
    font-weight: bold;
  }
  .author-position{
    font-style: italic;
    font-size: 16px;
  }
  font-size: 24px;
}
.catalog-carousel{
  img {
    width: auto!important;
    display: inline!important;
  }
  .owl-stage-outer{
    padding: 20px 0;
  }
  .goods-item{
    margin-bottom: 0;
    &:hover{
      .goods-item_layout{
        height: 100%;
      }
    }
  }
}
.catalog-preview{
  margin-top: 50px;
  padding-top: 120px;
  background-image: url(../images/331.png);
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 40px;
}
.catalog-preview-container{
  margin-left: auto;
  margin-right: auto;
  color: red;
  max-width: 1680px;
  .disabled-slide{
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  position: relative;
  .owl-nav{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 45px;
    transform: translateY(-50%);
    z-index: 333;
    button{
      position: absolute;
      top: 0;
      z-index: 333;
      background-color: var(--color-title)!important;
      width: 45px;
      height: 45px;
      color: #fff!important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px!important;
      font-weight: bold!important;
      border-radius: 50%;

      &.owl-prev{
        left: 20%;
        transform: translateX(calc(-100% - 30px));

        &::before{
          display: inline-block;
          margin-left: -3px;
          content: "\f104";
          font-family: FontAwesome;
        }
        &:active{
          transform: scale(0.9) translateX(calc(-100% - 30px));
        }
        @media (max-width: 767px) {
          left: 20px;
          transform: none;
          &:active{
            transform: scale(0.9);
          }
        }
      }
      &.owl-next{
        right: 20%;
        transform: translateX(calc(100% + 30px));


        &::before{
          content: "\f105";
          font-family: FontAwesome;
          display: inline-block;
          margin-right: -3px;

        }
        &:active{
          transform: scale(0.9) translateX(calc(100% + 30px));
        }
        @media (max-width: 767px) {
          right: 15px;
          transform: none;
          &:active{
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
.catalog-preview-container{
  .catalog-carousel{
    padding-bottom: 55px;
  }
  .owl-stage{
    .active:nth-child(1){
      opacity: 0.3;
    }
  }
  .owl-stage-outer{
    &::before,
    &::after{
      content: '';
      background-color: #fff;
      opacity: 0.7;
      position: absolute;
      display: block;
      top: 20px;
      bottom: 20px;
      width: calc(20% - 20px);
      z-index: 2;
      @media (max-width: 767px) {
        display: none;
      }
    }
    &::before{
      left: 0;
    }
    &::after{
      right: 0;
    }
  }
  .owl-dots{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .owl-dot{
    width: 30px;
    height: 2px;
    display: inline-block;
    margin: 0 5px;
    padding: 10px 0;
    span{
      display: inline-block;
      background-color: #b0b0b0;
      width: 30px;
      height: 2px;
    }
    &.active{
      pointer-events: none;
      span{
        background-color: var(--color-title);
      }
    }
  }
}

.advance{
  margin-top: 100px;
  .custom-h1{
    max-width: 600px;
  }
  .advance-item{
    display: flex;
    padding: 15px 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 12px 8px 2px rgba(#b0b0b0, 0.3);
    align-items: center;
    &>div{
      padding: 0 15px;
    }
  }
  .advance-text{
    font-size: 16px;
  }
  .advance-icon{
    max-width: 95px;
    max-height: 65px;
    width: 100%;
    height: 100%;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.grid-element:after {
  content: '';
  display: block;
  clear: both;
}
.advice {
  margin-top: 80px;
  background-image: url(../images/221bg.png);
  background-position: top center;
  background-size: 100%;
  padding-top: 120px;
  background-repeat: no-repeat;
  .masonry-grid{
    position: relative;
  }
  .grid-size {
    width: calc(100% / 3);
    @media (max-width: 767px){
      width: calc(100%);
    }
  }
  .grid-element {
    margin: 15px;

    &-w1 {
      width: calc(100% / 3 - 30px);
    }
    &-w2 {
      width: calc(100% * 2 / 3 - 30px);
    }
    &-w3 {
      width: calc(100% - 30px);
    }
    &-h1 {
      padding-bottom:  calc(100% / 3 - 30px);
    }
    &-h2 {
      padding-bottom: calc(100% * 2 / 3 - 30px)
    }
    &-h3 {
      padding-bottom: calc(100% - 30px);
    }
    &-sm-h1{
      @media (max-width: 767px) {
        padding-bottom: calc(100% - 30px);
      }
    }
    &-sm-w1{
      @media (max-width: 767px) {
        width: calc(100% - 30px);
      }
    }
  }


  .advice-item{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    &:hover{
      box-shadow: 0 12px 8px 2px rgba(#b0b0b0, 0.3);
    }
    .advice-item-dec{
      position: absolute;
      background-color: #fff;
      font-size: 16px;
      padding: 15px;
      bottom: 30px;
      left: 0;
      max-width: 100%;
      width: 336px;
    }
    .advice-item-dec{
      color: #000;
    }
  }
}

.about-us{
  margin-top: 70px;
  img{
    max-width: 100%;
  }
}
.custom-label{
  width: 100%;
}
.modal-header{
  padding: 5px 0;
  border-bottom: none;
  h4{
    font-size: 24px;
    font-weight: normal;
  }
}
.modal-body{
  padding: 10px 0;
}
.modal-content{
  padding: 15px 20px;
  @media (min-width: 768px){
    padding: 25px 50px;
  }
}
.narrow-modal{
  .modal-content{
    max-width: 380px;
    margin: 0 auto;
  }
}
.modal-header .close{
  margin-top: 2px;
  font-weight: normal;
}
.success-report-dec{
  color: #b0b0b0;
}
.success-order-dec{
  font-size: 14px;
  color: #b0b0b0;
}
.success-order-id{
  color: var(--color-title);
  font-size: 24px;
  font-weight: bold;
}
.mobile-catalog-trigger{
  text-align: center;
  margin-bottom: 35px;
  @media (min-width: 768px) {
    display: none;
  } 
}
.modal{
  .button-theme{
    text-align: center;
    display: block;
  }
}
.top-one-goods-item{
  .cart-item-image{
    width: 100%;
  }
  .cart-item-primary{
    width: 100%;
  }
  .cart-item-func{
    width: 100%;
    .button-theme{
      display: inline-block;;
    }
  }
  .cart-item-name{
    font-size: 20px;
  }
  .price{
    padding: 5px 0;
    .amount{
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.card-popup-body{
  .card-popup-item {
    padding: 5px 0;
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #b0b0b0;
    }
    .delete{
      width: 20px;
      flex-basis: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      text-align: center;
      align-self: center;
      &:hover{
        color: var(--color-title);
        cursor: pointer;
      }
    }
    .cart-item-image{
      width: 60px;
      flex-basis: 60px;
      flex-shrink: 0;
      flex-grow: 0;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
  }
  .cart-popup-item-primary{
    flex-basis: 100%;
    flex-shrink: 1;
    padding: 5px 7px;
    .cart-item-name{
      font-size: 14px;
      height: 42px;
      overflow: hidden;
    }
    .amount {
      font-size: 12px;
      color: #b0b0b0;
    }
  }
  .price{
    padding-left: 3px;
    color: var(--color-title);
    flex: 0 0 72px;
    text-align: right;
    @media (max-width: 676px) {
      flex: 0 0 40px;
    }
    .amount{
      font-size: 16px;
      font-weight: bold;
    }
    .currency{
      font-size: 14px;
    }
  }
}
.card-items-counter{
  position: absolute;
  top: -7px;
  right: -5px;
  background-color: var(--color-title);
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  line-height: 20px;
  text-align: center;
}
.mobile-cart-link{
  position: relative;
  .card-items-counter{
    top: 0;
    right: 0;
  }
}
.goods-container{
  .my-tabs{
    padding-top: 60px;
    padding-bottom: 25px;
    border-bottom: none;
    display: flex;
    li{
      padding: 0 10px;
      a{
        font-size: 14px;
        padding: 0 3px;
        color: var(--color-text);
        @media (min-width: 767px) {
          padding: 0 6px;
          font-size: 24px;
        }
        &:hover,
        &:focus{
          background-color: transparent;
        }
      }
      &.active{
        a {
          border-bottom: 1px dotted var(--color-title);
          background-color: transparent;
          color: var(--color-title);
          text-decoration-style: dotted;
        }
      }
    }
  }
}
.breadcrumb{
  &>li+li:before{
    color: #b0b0b0;
    content: "\f105";
    font-family: FontAwesome;
  }
  background-color: transparent;
  a{
    color: #b0b0b0;
  }
  li{
    &:last-child{
      a{
        pointer-events: none;
        color: var(--color-title);
      }
    }
  }
}